import { useToastAction } from "@metaforcelabs/metaforce-core";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom"
import { Formik } from "formik";
import { FormTextInput } from "../../../components/Form/FormTextInput";
import { createText, decryptTextCookies, encryptTextCookies, getFonts, getGroups, getLanguages, getStyles, getText, updateText } from "../../../api/textlibrary";
import { FormSelect } from "../../../components/Form/FormSelect";
import DateField from "../../../components/DateField";
import { OidcRoutesContext } from "../../../contexts";
import * as Constants from "../../../utils/constants"
import toast from 'react-hot-toast';
import * as _ from "lodash"
import * as yup from "yup";
import DateTimeParser from "../../../utils/DateTimeParser";
import GroupList from "../GroupList";
import { useCookies } from "react-cookie";
import Select from 'react-select';
import TextValueComponent from "./TextValueComponent";
import { useQuill } from '../../../hooks/useQuill';
import { useDispatch, useSelector } from "react-redux";
import { setPagination } from "../../../slices/textsSlice";

export default function TextDesign() {
    const navigate = useNavigate()
    const { id } = useParams();
    const { userProfile } = useContext(OidcRoutesContext);
    const { getQuillIdFromLanguageId, getQuill } = useQuill();
    const groupsRef = useRef([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const languagesRef = useRef([]);
    const stylesRef = useRef([]);
    const fontsRef = useRef([]);
    const templateTextRef = useRef({
        name: '',
        groupId: '',
        validFrom: '',
        validTo: '',
        values: [],
        state: Constants.templateTextState.active,
    });
    const [isGroupShowing, setIsGroupShowing] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const xmlRegex = /^[a-zA-Z_][a-zA-Z0-9._-]{0,19}$/;
    const pagination = useSelector((state) => state.texts.pagination);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(Constants.textDesignCookies);

    const [languageOptions, setLanguageOptions] = useState([]);
    const [languageSelectedOptions, setLanguageSelectedOptions] = useState([]);
    const languageSelectStyle = {
        control: (base, state) => ({
            ...base,
            // Overwrittes the different states of border
            border: state.isFocused ? "2px solid #9ca3af" : "1px solid #d1d5db",
            borderRadius: '0.375rem',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                border: "2px solid #9ca3af",
            }
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#f3f4f6' : base.backgroundColor,
            color: '#374151',
            '&:active': {
                backgroundColor: '#e5e7eb'
            }
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#f3f4f6'
        }),
        multiValueLabel: (base) => ({
            ...base,
            color: '#374151'
        })
    }

    const [textValuesToShow, setTextValuesToShow] = useState([]);
    const textValuesToShowRef = useRef();
    textValuesToShowRef.current = textValuesToShow;

    const textSchemaRef = useRef({
        name: yup.string().required("Required"),
        identifier: yup.string().nullable().required("Required").max(40).test(
            'identifier',
            null,
            (value) => {
                if (value?.includes('#') || value?.includes(' ')) {
                    return new yup.ValidationError(
                        'Invalid identifier! An identifier can not be composed of # or space',
                        null,
                        'identifier',
                    );
                }
                return true;
            }),
        groupId: yup.string().test(
            'groupId',
            null,
            (obj, e) => {
                if (e.parent.groupId && e.parent.groupId !== '') {
                    return true;
                }
                return new yup.ValidationError(
                    'Required',
                    null,
                    'groupId'
                );
            }),
        validTo: yup.string().test(
            'validTo',
            null,
            (obj, e) => {
                if (e.parent.validFrom && e.parent.validTo && DateTimeParser.toDateObject(e.parent.validFrom) > DateTimeParser.toDateObject(e.parent.validTo)) {
                    return new yup.ValidationError(
                        'Valid-to date must be greater than valid-from date.',
                        null,
                        'validTo'
                    );
                }
                return true;
            }),
        languageId: yup.string().test(
            'languageId',
            null,
            (obj, e) => {
                let openedtextValues = textValuesToShowRef.current.filter(textValue => textValue.open);
                if (openedtextValues?.length === 0) {
                    return new yup.ValidationError(
                        'At least one language is required',
                        null,
                        'languageId'
                    );
                }
                return true;
            }),
    });

    const addToSchema = (languages) => {
        languages.forEach(language => {
            textSchemaRef.current[language.id] = yup.string().test(
                language.id,
                null,
                (obj) => {
                    // check if the text is empty when it is open.
                    let foundTextValue = textValuesToShowRef.current.find(x => x.value.languageId === language.id);
                    const quillId = getQuillIdFromLanguageId(language.id);
                    let quill = getQuill(quillId);
                    if (foundTextValue.open && quill) {
                        const text = quill.getText(0, quill.getLength());
                        if (!text || text === Constants.emptyQuillTextContent) {
                            return new yup.ValidationError(
                                'Required',
                                null,
                                language.id
                            );
                        }
                    }
                    return true;
                }
            )
        })
    }

    const loadAction = useToastAction();

    const handleGroupData = (args) => {
        groupsRef.current = _.orderBy(args.groups, 'name', 'asc');
        let newGroupOptions = groupsRef.current?.map(x => { return { name: x.name, value: x.id } });
        newGroupOptions.unshift({ name: 'Select Group', value: '' });
        setGroupOptions(newGroupOptions);
        return newGroupOptions;
    }

    const loadData = async () => {
        loadAction.execute(async () => {
            let [newGroups, newLanguages, newStyles, newFonts, newTemplateText, templateTextCookies] = await Promise.all([
                getGroups(),
                getLanguages(),
                getStyles(),
                getFonts(),
                id ? getText(id) : () => { },
                !_.isEmpty(cookies) && cookies[Constants.textDesignCookies] ? decryptTextCookies(cookies[Constants.textDesignCookies]) : () => { },
            ]);

            newStyles = _.orderBy(newStyles, 'name', 'asc');
            stylesRef.current = newStyles;
            newFonts = _.orderBy(newFonts, 'name', 'asc');
            fontsRef.current = newFonts;

            if (!newStyles || newStyles.length === 0) {
                toast.error('Style is not defined. Please create style.', {
                    duration: Infinity,
                });
                setDisabled(true);
                return;
            }

            const newGroupOptions = handleGroupData({ groups: newGroups });
            languagesRef.current = newLanguages;

            setLanguageOptions(newLanguages.map(language => { return { label: language.name, value: language.id } }));

            addToSchema(newLanguages);

            let clonedTextValuesToShow = [];
            newLanguages.forEach(language => {
                clonedTextValuesToShow.push({
                    open: false,
                    languageName: language.name,
                    value: {
                        languageId: language.id,
                        description: '',
                        text: '',
                    },
                });
            })

            if (id) {
                if (!newTemplateText.validFrom) {
                    newTemplateText.validFrom = '';
                }
                if (!newTemplateText.validTo) {
                    newTemplateText.validTo = '';
                }
                templateTextRef.current = newTemplateText;

                let newLanguageSelectedOptions = [];
                templateTextRef.current.values.forEach(templateTextValue => {
                    let language = languagesRef.current?.find(x => x.id === templateTextValue.languageId);
                    if (language) {
                        newLanguageSelectedOptions.push({ label: language.name, value: language.id });
                        let foundTextValue = clonedTextValuesToShow.find(x => x.value.languageId === language.id);
                        if (foundTextValue) {
                            foundTextValue.open = true;
                            foundTextValue.value = _.cloneDeep(templateTextValue)
                        }
                    }
                })
                setLanguageSelectedOptions(newLanguageSelectedOptions);
            } else {
                templateTextRef.current.groupId = newGroupOptions[0].value;
                if (templateTextCookies && templateTextCookies.groupId) {
                    const groupOption = newGroupOptions.find(x => x.value === templateTextCookies.groupId);
                    if (groupOption) {
                        templateTextRef.current.groupId = groupOption.value;
                    }
                }

                templateTextRef.current.validFrom = '';
                if (templateTextCookies && templateTextCookies.validFrom) {
                    templateTextRef.current.validFrom = templateTextCookies.validFrom;
                }

                templateTextRef.current.validTo = '';
                if (templateTextCookies && templateTextCookies.validTo) {
                    templateTextRef.current.validTo = templateTextCookies.validTo;
                }

                templateTextRef.current.values = [];
            }

            setTextValuesToShow(clonedTextValuesToShow);

        }, "Failed to load data");
    }

    useEffect(() => {
        loadData();
    }, []);

    const handleLanguageChange = (options) => {
        let clonedTextValuesToShow = _.cloneDeep(textValuesToShow);
        clonedTextValuesToShow.forEach(textValue => {
            const foundLanguage = options.find(option => option.value === textValue.value.languageId);
            textValue.open = foundLanguage ? true : false;
        })
        setTextValuesToShow(clonedTextValuesToShow);
        setLanguageSelectedOptions(options);
    }

    const handleTextValueClose = (languageId) => {
        let clonedTextValuesToShow = _.cloneDeep(textValuesToShow);
        let foundTextValue = clonedTextValuesToShow.find(textValue => textValue.value.languageId === languageId);
        if (foundTextValue) {
            foundTextValue.open = false;
            setTextValuesToShow(clonedTextValuesToShow);
        }

        let clonedLanguageSelectedOptions = _.cloneDeep(languageSelectedOptions);
        let index = clonedLanguageSelectedOptions.findIndex(x => x.value === languageId);
        if (index >= 0) {
            clonedLanguageSelectedOptions.splice(index, 1);
            setLanguageSelectedOptions(clonedLanguageSelectedOptions);
        }
    }

    const handleDescriptionChange = (textValue, description) => {
        let clonedTextValuesToShow = _.cloneDeep(textValuesToShowRef.current);
        let foundTextValue = clonedTextValuesToShow.find(x => x.value.languageId === textValue.value.languageId);
        if (foundTextValue) {
            foundTextValue.value.description = description;
            setTextValuesToShow(clonedTextValuesToShow);
        }
    }

    const handleTextChange = (textValue, text) => {
        let clonedTextValuesToShow = _.cloneDeep(textValuesToShowRef.current);
        let foundTextValue = clonedTextValuesToShow.find(x => x.value.languageId === textValue.value.languageId);
        if (foundTextValue) {
            foundTextValue.value.text = text;
            setTextValuesToShow(clonedTextValuesToShow);
        }
    }

    const handleSave = async (values) => {
        const clonedTemplateText = _.cloneDeep(templateTextRef.current);
        clonedTemplateText.name = values.name;
        clonedTemplateText.groupId = values.groupId;
        clonedTemplateText.validFrom = DateTimeParser.toDateUTCObject(values.validFrom);
        clonedTemplateText.validTo = DateTimeParser.toDateUTCObject(values.validTo);

        clonedTemplateText.values = [];
        textValuesToShow.forEach(textValue => {
            if (textValue.open) {
                clonedTemplateText.values.push(_.cloneDeep(textValue.value));
            }
        })

        const templateTextCookiesValue = await encryptTextCookies({
            groupId: clonedTemplateText.groupId,
            validFrom: clonedTemplateText.validFrom,
            validTo: clonedTemplateText.validTo,
        });
        setCookie(Constants.textDesignCookies, templateTextCookiesValue, { path: '/', secure: true });

        if (id) {
            await updateText(clonedTemplateText);
        } else {
            await createText(clonedTemplateText);
        }

        dispatch(setPagination({
            pagination: {
                ...pagination,
                currentPage: 0,
            },
        }));
        navigate('/texts');
    }

    if (isGroupShowing) {
        return (
            <GroupList
                templateText={templateTextRef.current}
                onClose={async () => {
                    setIsGroupShowing(false);
                    const newGroups = await getGroups();
                    handleGroupData({ groups: newGroups });
                }}
            />
        )
    }

    return (
        <>
            <Formik
                initialValues={templateTextRef.current}
                enableReinitialize={true}
                validationSchema={yup.object().shape(textSchemaRef.current)}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                        await handleSave(values);

                    } catch (err) {
                        if (err.response?.status === 400 && err.response?.data?.Message?.startsWith("TextLibraryErrors:")) {
                            const message = err.response?.data?.Message.replace("TextLibraryErrors:", "");
                            if (message.includes('text name')) {
                                setErrors({ name: 'Already exists' });
                            } else if (message.includes('text identifier')) {
                                setErrors({ identifier: 'Already exists' });
                            }
                            toast.error(message);
                        } else {
                            toast.error("Failed to save text");
                        }
                    }
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    errors,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    validateForm,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form className="" onSubmit={handleSubmit} >
                        <div className="flex-1 relative max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4 h-full flex flex-col">
                            <div className="shadow-sm rounded-lg border border-gray-200 bg-white shadow rounded-lg flex flex-col">
                                <div className="flex flex-col h-full">
                                    <div className="space-y-8 divide-y divide-gray-200 p-8">
                                        <div>
                                            {/* <div className="border-b py-3">
                                            
                                                <h1 className="text-3xl font-bold">{id ? "Edit Text" : "Create Text"}</h1>
                                            </div> */}
                                            <div className="sm:col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Text</h3>
                                            </div>

                                            <div className="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-8">
                                                <div className="col-span-1 sm:col-span-5">
                                                    <FormTextInput
                                                        name="name"
                                                        label={"Name"}
                                                        value={values.name}
                                                        disabled={disabled}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            templateTextRef.current.name = e.target.value;
                                                        }}
                                                        error={errors.name}
                                                    />
                                                </div>
                                                <div className="col-span-1 sm:col-span-3">
                                                    <FormTextInput
                                                        name="identifier"
                                                        label={"Identifier"}
                                                        value={values.identifier}
                                                        disabled={disabled}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            templateTextRef.current.identifier = e.target.value;
                                                        }}
                                                        error={errors.identifier}
                                                    />
                                                </div>

                                                {/* <div className="col-span-1 sm:col-span-4">
                                                    <DateField
                                                        navbar={true}
                                                        name="validFrom"
                                                        label={"Valid from"}
                                                        dataCy="validFrom"
                                                        value={DateTimeParser.toLocaleDateString(values.validFrom, userProfile.languageCode)}
                                                        cultureCode={userProfile?.languageCode}
                                                        disabled={disabled}
                                                        onChange={(v, e) => {
                                                            setFieldValue('validFrom', v);
                                                            templateTextRef.current.validFrom = DateTimeParser.toDateObject(v)
                                                        }}
                                                    />
                                                </div>

                                                <div className="col-span-1 sm:col-span-4 mt-6 sm:mt-0">
                                                    <DateField
                                                        navbar={true}
                                                        name="validTo"
                                                        label={"Valid to"}
                                                        dataCy="validTo"
                                                        value={DateTimeParser.toLocaleDateString(values.validTo, userProfile.languageCode)}
                                                        cultureCode={userProfile?.languageCode}
                                                        disabled={disabled}
                                                        onChange={(v, e) => {
                                                            setFieldValue('validTo', v);
                                                            templateTextRef.current.validTo = DateTimeParser.toDateObject(v)
                                                        }}
                                                        error={errors.validTo}
                                                    />
                                                </div> */}

                                                <div className="sm:col-span-4">
                                                    <FormSelect
                                                        name="groupId"
                                                        label={"Group"}
                                                        disabled={disabled}
                                                        value={values.groupId}
                                                        options={groupOptions}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            templateTextRef.current.groupId = e.target.value;
                                                        }}
                                                        error={errors.groupId}
                                                    />
                                                </div>
                                                {/* <div className="sm:col-span-4 relative flex flex-col">
                                                    <label className="hidden sm:block text-sm font-medium text-gray-700">&nbsp;</label>
                                                    <div className="mt-1 h-full">
                                                        <button type="button"
                                                            className="h-full px-5 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white 
                                                            bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                                                            disabled={disabled}
                                                            onClick={() => {
                                                                setIsGroupShowing(true)
                                                            }}
                                                        >
                                                            Edit Groups
                                                        </button>
                                                    </div>
                                                    {errors.groupId && <div className="mt-1 text-sm text-red-600 font-medium">&nbsp;</div>}
                                                </div> */}
                                                <div className=" sm:col-span-4 ">
                                                    <label className="block text-sm font-medium text-gray-700 mb-1">Language</label>
                                                    <Select
                                                        styles={languageSelectStyle}
                                                        isMulti={true}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                        isDisabled={disabled}
                                                        value={languageSelectedOptions}
                                                        onChange={handleLanguageChange}
                                                        options={languageOptions}
                                                    />
                                                    <div className="text-sm text-red-600 font-medium">{errors.languageId}</div>
                                                </div>

                                                <div className="sm:col-span-8 mt-4 flex justify-between items-center">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Languages</h3>
                                                    {languageSelectedOptions.length > 0 && (
                                                        <div>
                                                            <button type="button"
                                                                className="justify-center inline-flex items-center px-5 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-md bg-white 
                                                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                                                                onClick={() => { navigate('/texts') }}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button type="button"
                                                                className="ml-4 justify-center inline-flex items-center px-5 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-md text-white 
                                                                bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                                                                disabled={disabled}
                                                                onClick={async () => {
                                                                    const res = await validateForm();
                                                                    if (_.isEmpty(res)) {
                                                                        handleSubmit();
                                                                    }
                                                                }}
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="sm:col-start-1 sm:col-span-8 space-y-5 flex flex-col">
                                                    {textValuesToShow && textValuesToShow.map(textValue => (
                                                        <TextValueComponent currentTextValue={textValue} disabled={disabled} stylesRef={stylesRef} fontsRef={fontsRef} errors={errors}
                                                            onClose={handleTextValueClose} onDescriptionChange={handleDescriptionChange} onTextChange={handleTextChange}
                                                        />
                                                    ))}
                                                </div>

                                                <div className="sm:col-span-4">
                                                    <div className="text-sm">
                                                        {id && <>Last saved: {DateTimeParser.toLocaleDateTimeString(templateTextRef.current.modifiedTime, userProfile.languageCode)} By {templateTextRef.current.modifiedByUserName}</>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end bg-gray-50 px-8 py-4">
                                        <button type="button"
                                            className="justify-center inline-flex items-center px-5 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-md bg-white 
                                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400"
                                            onClick={() => { navigate('/texts') }}
                                        >
                                            Cancel
                                        </button>
                                        <button type="button"
                                            className="ml-4 justify-center inline-flex items-center px-5 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-md text-white 
                                            bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                                            disabled={disabled}
                                            onClick={async () => {
                                                const res = await validateForm();
                                                if (_.isEmpty(res)) {
                                                    handleSubmit();
                                                }
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}